import React, { useState, useRef, useEffect } from 'react';
import { Router, Link, Location, useLocation } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faCompass,
  faComment,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
const sidebarNavItems = [
  {
    display: 'Home',
    icon: faHome,
    to: '',
    section: '',
  },
  {
    display: 'Products',
    icon: faCompass,
    to: 'products',
    section: 'products',
  },
  {
    display: 'Comments',
    icon: faComment,
    to: 'comments',
    section: 'comments',
  },
  {
    display: 'Management',
    icon: faGear,
    to: 'management',
    section: 'management',
  },
];
const AdminSidebar = ({ className }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stepHeight, setStepHeight] = useState(0);
  const sidebarRef = useRef<HTMLDivElement | any>(null);
  const indicatorRef = useRef<HTMLDivElement | any>();
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      const sidebarItem = sidebarRef.current.querySelector(
        '.admin-sidebar--menu__item'
      );
      indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
      setStepHeight(sidebarItem.clientHeight);
    }, 50);
  }, [activeIndex]);
  useEffect(() => {
    const curPath = window.location.pathname.split('/')[2];
    if (!curPath) {
      setActiveIndex(0);
      return;
    }
    const activeItem = sidebarNavItems.findIndex(
      item => item.section === curPath
    );
    console.log(activeItem);
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);
  // change active index
  //   useEffect(() => {
  //     const activeItem = sidebarNavItems.findIndex(
  //       item => item.section === curPath
  //     );
  //     setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  //   }, [activeIndex]);
  return (
    <div className={`admin-sidebar fixed ${className}`}>
      <div className="admin-sidebar--top">
        <h1 className="text-gradient">PRODUCTS</h1>
        <h1>DASHBOARD</h1>
      </div>
      <div className="admin-sidebar--menu" ref={sidebarRef}>
        <div
          className="admin-sidebar--menu__indicator"
          style={{
            transform: `translateX(-50%) translateY(${
              activeIndex * stepHeight
            }px)`,
          }}
        ></div>
        {sidebarNavItems.map((item, index) => (
          <Link key={item.to} to={`admin/${item.to}`}>
            <div
              className={`admin-sidebar--menu__item ${
                index == activeIndex ? 'active' : ''
              }`}
              //   onClick={() => setActiveIndex(index)}
              ref={indicatorRef}
            >
              <FontAwesomeIcon
                className="admin-sidebar--menu__item__icon"
                icon={item.icon}
                size={'lg'}
              />
              <div className="admin-sidebar--menu__item__text">
                {item.display}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AdminSidebar;
