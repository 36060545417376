import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Switch from '../../customSwitch';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

const AdminProductItem = ({ data }) => {
  const [active, setActve] = useState(false);
  useEffect(() => {
    console.log(data.name);
  });
  return (
    <div className="products-list__item">
      <div className="products-list__item--content">
        <div className="flex items-center">
          <div className="products-list__item--content--logo">
            <img
              src={`${data.logo}`}
              style={{
                objectFit: 'contain',
                width: 60,
                height: 60,
              }}
              className="m-0"
            />
          </div>
          <div className="flex flex-col  ml-2 mr-2 flex-1">
            <div className="products-list__item--content--title">
              {data.name}
            </div>
            <div className="mt-2 text-sm ">Updated: 20/06/2023</div>
          </div>
          <div className="flex items-start h-[60px]">
            <Link to={`product/${data.productId}`}>
              <FontAwesomeIcon size="lg" icon={faEdit} />
            </Link>
          </div>
        </div>
        <div className="flex items-center justify-between mt-3">
          <div className="flex text-[#000] text-sm">
            <span>Comments: </span>
            <span className="ml-1">7</span>
          </div>
          <div className="flex">
            <span className="h-[12px] w-[12px] rounded-full bg-[#49dc4f]"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProductItem;
