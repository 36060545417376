import React from 'react';

const TextInput = ({
  name,
  onChange = (e: any) => {},
  icon,
  height = 48,
  width = '100%',
  borderRadius = 12,
  className = '',
  noOutline = false,
}: {
  name?: string;
  onChange?: Function;
  icon?: any;
  height?: number;
  width?: string | number;
  borderRadius?: number;
  className?: string;
  noOutline?: boolean;
}) => {
  return (
    <div className={className}>
      <div
        className={`text-input ${!noOutline && 'style-outline'} `}
        style={{ height: height, width: width }}
      >
        {icon && <div className="text-input--icon">{icon}</div>}
        <input name={name} type="text" onChange={e => onChange(e)} />
      </div>
    </div>
  );
};

export default TextInput;
