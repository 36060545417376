import React, { useState, useEffect, useRef } from 'react';

interface Option {
  key: any;
  value: any;
}
const DropdownInput = ({
  onChange = (e: any) => {},
  options = [],
  onClickOutside,
  className,
}: {
  onChange?: Function;
  options?: Option[];
  onClickOutside?: Function;
  className?: string;
}) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [displayValue, setDisplayValue] = useState();
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowDropdown(false);
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    <div className={className}>
      <div
        ref={ref}
        className="dropdown-input"
        onClick={() => setIsShowDropdown(!isShowDropdown)}
      >
        <div className="dropdown-input--display">
          <div>{displayValue}</div>
          <i className="arrow down"></i>
        </div>

        <div className={`dropdown-input--options ${isShowDropdown && 'show'}`}>
          <div className="dropdown-input--options__content">
            {options.map((op, index) => {
              return (
                <div
                  className="dropdown-input--options__item"
                  onClick={() => {
                    onChange(op);
                    setIsShowDropdown(false);
                    setDisplayValue(op.value);
                  }}
                  key={op.value}
                >
                  {op.value}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownInput;
