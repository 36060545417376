import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import AdminLayout from '../../layouts/adminLayout';
import HomePage from '../../components/admin/home';
import CommentPage from '../../components/admin/comment/comment';
import ManagementPage from '../../components/admin/management';
import ProductsPage from '../../components/admin/product/productsPage';

let Home = (props: RouteComponentProps) => <HomePage />;
let Comments = (props: RouteComponentProps) => <CommentPage />;
let Management = (props: RouteComponentProps) => <ManagementPage />;
let Products = (props: RouteComponentProps) => <ProductsPage />;

const Admin = () => {
  return (
    <AdminLayout>
      <Router
        basepath="/admin"
        style={{ height: '100%' }}
        className="flex flex-1"
      >
        <Home path="/" />

        <Comments path="/comments" />
        <Management path="/management" />
        <Products path="/products/*" />
      </Router>
    </AdminLayout>
  );
};

export default Admin;
