import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import TextInput from '../textInput';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  Status,
  createProduct,
  getProductById,
} from '../../../redux/features/product/productSlice';
import Switch from '../../customSwitch';

// Add product or update page
const ProductDetailPage = ({ isCreate = false, ...props }) => {
  const { products } = useAppSelector(state => state.product);
  const dispatch = useAppDispatch();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();
  };
  const productFormRef = useRef<HTMLFormElement>(null);
  const [tabActive, setTabActive] = useState('tab1');

  useEffect(() => {
    if (props.id && !isCreate) dispatch(getProductById(props.id));
    productFormRef.current
      ?.querySelector('input[name="name"]')
      ?.setAttribute('value', 'asdasdasd');
  });

  const loadProductData = (data: any) => {};
  return (
    <div className="flex justify-start flex-col items-left h-full pr-[20px]  2xl:mx-[15%] xl:mx-[2%] sm:mx-[5%] mx-[0%]">
      <div className="flex items-center justify-start mr-[20px]">
        <Link to="/admin/products">
          <div className="flex items-center text-gradient">
            <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
            <span>Products</span>
          </div>
        </Link>
        <div className="ml-6">
          <h1 className="m-0">Add Product</h1>
        </div>
      </div>
      {/* <div className="h-[60px]"></div> */}
      <div className="w-full pl-[5%] h-full flex-1 flex min-h-[300px] justify-center items-start bg-[white] rounded-xl mt-2">
        <div className="w-[200px] h-full pt-6">
          <InfoSidebar onChange={v => setTabActive(v)} tabActive={tabActive} />
        </div>
        <div className="flex-1 w-full h-full px-6 flex flex-col items-start justify-start overflow-auto custom-scrollbar pt-6">
          <form
            ref={productFormRef}
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <TabContent active={tabActive == 'tab1'}>
              <InfoFormMain onNextStep={() => setTabActive('tab2')} />
            </TabContent>
            <TabContent active={tabActive == 'tab2'}>
              <InfoFormMedia onNextStep={() => setTabActive('tab3')} />
            </TabContent>
            <TabContent active={tabActive == 'tab3'}>
              <InfoFormChecklist
                onSubmit={() => {
                  const formData = new FormData(productFormRef.current!);
                  console.log('sunmit');
                  dispatch(createProduct(formData));
                }}
              />
            </TabContent>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ProductDetailPage;

// Sidebar product information tab list
const infoSideBarItems = {
  main: {
    display: 'Main info',
    icon: '🎯',
    color: '#ff5722',
    id: 'tab1',
  },
  media: {
    display: 'Link & media',
    icon: '📎',
    color: '#607D8A',
    id: 'tab2',
  },
  launch: {
    display: 'Launch',
    icon: '🚀',
    color: '#4caf50',
    id: 'tab3',
  },
};

// Product info Sidebar components
const InfoSidebar = ({ onChange, tabActive }) => {
  const [indexSelected, setIndexSelected] = useState(0);
  return (
    <div className="info-sidebar flex flex-col items-start">
      {Object.keys(infoSideBarItems).map((key, index) => {
        return (
          <div
            className={`info-sidebar--item `}
            onClick={() => {
              setIndexSelected(index);
              onChange(infoSideBarItems[key].id);
            }}
          >
            <span className="mr-1 text-xl">{infoSideBarItems[key].icon}</span>
            <span
              className={`${
                tabActive === infoSideBarItems[key].id && 'active'
              }`}
            >
              {infoSideBarItems[key].display}
            </span>
          </div>
        );
      })}
    </div>
  );
};

// Tab content
const TabContent = ({ active = false, children }) => {
  return <div className={`tab-content ${active && 'active'}`}>{children}</div>;
};

// Product main info tab
const InfoFormMain = ({ onNextStep }) => {
  const [indexSelected, setIndexSelected] = useState(0);
  return (
    <div className="flex flex-col items-start w-full">
      <h2 className="m-0">We need to some information of new product!</h2>
      <div className="add-product-form flex flex-col 2xl:w-[80%] xl:w-[90%] sm:w-[100%]">
        <div className="flex items-center justify-between">
          <h2 className="mt-6">Basic</h2>
          <div>English</div>
        </div>
        <div className="form-label">Name of the product</div>
        <TextInput name="name" height={40} />
        <div className="form-label">Short description</div>
        <TextInput name="short" height={40} />
        <div className="form-label">Full description</div>
        <div className="textarea-input style-outline">
          <textarea name="desc" className="" rows={6}></textarea>
        </div>
        <h2 className="mt-6">Status</h2>
        <div className="form-label">Product status </div>
        <div className="textarea-input">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={0}
            name="status"
          >
            <FormControlLabel value={0} control={<Radio />} label="Demo" />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Implementing"
            />
          </RadioGroup>
        </div>
        <h2 className="mt-6">Techniques</h2>
        <div className="form-label">Platform Tag</div>
        <TextInput name="platform" height={40} />
        <div className="form-label">Technology frameworks</div>
        <TextInput name="tech" height={40} />
        <div className="mt-6">
          <p className="m-0 mb-2 text-[#888]">
            Click to below button or "Image & Media" part on tab bar to add
            media for product
          </p>
          <button className="linear-button mb-[100px]" onClick={onNextStep}>
            Next step: {infoSideBarItems.media.display}
          </button>
        </div>
      </div>
    </div>
  );
};

// Product media tab
const InfoFormMedia = ({ onNextStep }) => {
  const [indexSelected, setIndexSelected] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedScreenShotsImage, setSelectedScreenShotsImage] = useState([]);
  const logoInput = useRef<HTMLInputElement>(null);
  const screenShotsInput = useRef<HTMLInputElement>(null);

  const [screenIndexSelected, setScreenIndexSelected] = useState(0);
  return (
    <div className="flex flex-col items-start w-full">
      <h2 className="m-0">The best product media to show </h2>
      <div className="add-product-form flex flex-col 2xl:w-[80%] xl:w-[90%] sm:w-[100%]">
        <div className="form-label">Choose logo</div>
        <div className="flex">
          <div
            className={`image-upload`}
            style={{ border: selectedImage ? 'none' : '' }}
          >
            {selectedImage && (
              <img
                alt="not found"
                className="image-upload__logo"
                src={URL.createObjectURL(selectedImage[0]!)}
              />
            )}
          </div>
          <div className="flex flex-col felx-1 pl-6">
            <div>
              <input
                ref={logoInput}
                type="file"
                style={{ display: 'none' }}
                onChange={(event: any) => {
                  setSelectedImage(event.target.files);
                }}
                name="logo"
                accept=".svg,.png"
              />
              <button
                className="button-upload"
                onClick={() => logoInput.current?.click()}
              >
                Upload an image
              </button>
            </div>
            <p className="m-0 mt-2 text-[#888]">
              Logo product must be .SVG or .PNG
            </p>
            <p className="m-0 text-[#888]">Recommend size: 240 x 240</p>
          </div>
        </div>
        <h2 className="mt-6">Gallery</h2>
        <div className="form-label">Screenshots</div>
        <p className="m-0 mb-2 text-[#888]">
          Upload limit 3 screenshots of product
        </p>
        <div className="flex">
          <div className="h-[260px] w-[100%] image-upload flex-col justify-center flex items-center">
            <input
              ref={screenShotsInput}
              type="file"
              style={{ display: 'none' }}
              onChange={(event: any) => {
                setSelectedScreenShotsImage(Array.from(event.target.files));
              }}
              multiple
              name="screenShots"
              accept=".jpg,.png"
            />

            {selectedScreenShotsImage[screenIndexSelected] ? (
              <div className="flex w-full justify-center h-full">
                {
                  <img
                    alt="not found"
                    className="image-upload__screen__selected m-0"
                    src={URL.createObjectURL(
                      selectedScreenShotsImage[screenIndexSelected]!
                    )}
                  />
                }
              </div>
            ) : (
              <>
                <div
                  className="text-[#004bc8] cursor-pointer"
                  onClick={() => screenShotsInput.current?.click()}
                >
                  Select from Browser
                </div>
                <div className="text-sm text-[#888] text-center">
                  <div>Upload best screenshots for product</div>
                  <div>1270x760px or higher recommended</div>
                </div>
              </>
            )}
          </div>
        </div>
        {selectedScreenShotsImage && (
          <div className="flex w-full mt-2 items-center flex-wrap w-full">
            {selectedScreenShotsImage.map((image: File, index: any) => {
              return (
                <div
                  className={`image-screen__items rounded-lg overflow-hidden cursor-pointer ${
                    index == screenIndexSelected && 'screen-selected'
                  }`}
                  onClick={() => setScreenIndexSelected(index)}
                >
                  <img alt="not found" src={URL.createObjectURL(image!)} />
                </div>
              );
            })}
            <div
              className="text-[#004bc8] cursor-pointer ml-5"
              onClick={() => screenShotsInput.current?.click()}
            >
              Edit
            </div>
          </div>
        )}
        <h2 className="mt-6">Links</h2>
        <div className="form-label">Main link of product</div>
        <TextInput name="intro_link" height={40} />
        <div className="form-label">Intro page link</div>
        <TextInput name="product_link" height={40} />
        <div className="mt-6">
          <p className="m-0 mb-2 text-[#888]">
            Click to below button or "Lunch checklist" part on tab bar to review
            for submit new product
          </p>
          <button className="linear-button mb-[100px]" onClick={onNextStep}>
            Next step: {infoSideBarItems.launch.display}
          </button>
        </div>
      </div>
    </div>
  );
};

// Product checklist & save tab
const InfoFormChecklist = ({ onNextStep = () => {}, onSubmit = () => {} }) => {
  const [indexSelected, setIndexSelected] = useState(0);
  const { createProductStatus } = useAppSelector(state => state.product);
  const dispatch = useAppDispatch();
  return (
    <div className="flex flex-col items-start w-full">
      <h2 className="m-0">Recheck product information and Submit </h2>
      <div className="add-product-form flex flex-col 2xl:w-[80%] xl:w-[90%] sm:w-[100%]">
        <div className="mt-6">
          <div className="flex justify-between mb-4">
            <span className="mr-2">Active</span>
            <Switch name={'is_active'} isOn={true} handleToggle={() => {}} />
          </div>
          <div className="flex justify-between mb-4">
            <span className="mr-2">Show on the home page</span>
            <Switch name={'is_home'} isOn={true} handleToggle={() => {}} />
          </div>
          <p className="m-0 mb-2 text-[#888]">Save product</p>

          <button
            className="c-button mb-[100px] w-[200px]"
            type="submit"
            onClick={onSubmit}
          >
            Submit
          </button>
          <div>
            {createProductStatus.status != Status.idle &&
              (createProductStatus.status == Status.pending ? (
                <span>Progress...</span>
              ) : (
                <span>{createProductStatus.message}</span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
function useParams(): { id: any } {
  throw new Error('Function not implemented.');
}
