import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import TextInput from '../textInput';
import { Link } from 'gatsby';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchProduct } from '../../../redux/features/product/productSlice';
import AdminProductItem from './AdminProductItem';
const ProductsListPage = () => {
  const { products } = useAppSelector(state => state.product);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchProduct());
  }, []);
  return (
    <div className="flex justify-start flex-col items-left h-full w-full">
      <div className="flex items-center justify-between mr-[20px]">
        <h1 className="m-0">Products</h1>
        <Link to="add-product">
          <button className="c-button ml-6">
            <div className="c-button__text">+ Add product</div>
          </button>
        </Link>
      </div>
      <div className="w-full h-full flex-1 flex flex-col min-h-[300px]">
        <div className="products-search">
          <TextInput
            onChange={e => {
              console.log(e.target.value);
            }}
            icon={<FontAwesomeIcon size="lg" icon={faSearch} />}
            className="w-[30vw]"
            noOutline={true}
          />
        </div>
        <div className="flex products-list overflow-auto flex-1 content-baseline mt-2 pr-[10px]">
          {[...products].map((item, index) => {
            return <AdminProductItem key={item._id} data={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ProductsListPage;
