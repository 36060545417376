import React from 'react';
const AdminHeader = () => {
  return (
    <header className="w-full fixed z-50 bg-header admin-header flex items-center">
      <nav
        className="flex flex-row justify-between px-10 w-full items-center"
        style={{ fontWeight: 600 }}
      ></nav>
    </header>
  );
};

export default AdminHeader;
