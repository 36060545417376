import React from 'react';

const ManagementPage = () => {
  return (
    <div className="flex justify-center items-center h-full w-full">
      <h1>ManagementPage</h1>
    </div>
  );
};

export default ManagementPage;
