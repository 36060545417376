import React, { useState } from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import ProductsListPage from './productsList';
import ProductDetailPage from './productDetail';
let ProductsList = (props: RouteComponentProps) => <ProductsListPage />;
let AddProduct = (props: RouteComponentProps) => (
  <ProductDetailPage isCreate={true} {...props} />
);
let DetailProduct = (props: RouteComponentProps) => (
  <ProductDetailPage {...props} />
);
const ProductsPage = () => {
  return (
    <Router basepath="admin/products" className="w-full">
      <ProductsList path="/" />
      <AddProduct path="/add-product" />
      <DetailProduct path="/product/:id" />
    </Router>
  );
};

export default ProductsPage;
