import React, { useState, useLayoutEffect } from 'react';
import AdminHeader from '../components/admin/header';
import '../../static/assets/styles/admin.scss';
import '../../static/assets/styles/admin-components.scss';
import AdminSidebar from '../components/admin/sideBar';
const AdminLayout = ({ children }) => {
  return (
    <>
      <AdminHeader />
      <AdminSidebar className={''} />
      <div
        className="flex w-full h-full justify-end"
        style={{ justifyContent: 'flex-end' }}
      >
        <main className="admin-main">
          <div className="h-full admin-main--content">{children}</div>
        </main>
      </div>
    </>
  );
};

export default AdminLayout;
