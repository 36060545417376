import { Checkbox } from '@mui/material';
import React from 'react';
import DropdownInput from '../dropdownInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGear,
  faCheck,
  faClose,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
const CommentPage = () => {
  return (
    <div className="h-full w-full flex-1 flex flex-col overflow-hidden">
      {/* <div className="flex items-center">
        <h1 className="m-0">Comments</h1>
        <FontAwesomeIcon className="ml-3" icon={faGear} />
      </div> */}
      <div className="flex  flex-1 h-full 2xl:mx-[15%] xl:mx-[2%] sm:mx-[5%] mx-[0%]">
        <div className="flex-1 flex flex-col ">
          <div className="flex h-[64px] items-center justify-between">
            <span>Pending comments</span>
            <div className="flex items-center">
              Select All
              <Checkbox value={true} />
            </div>
          </div>
          <div className="flex-1 overflow-auto custom-scrollbar ">
            {[...Array(10)].map((x, i) => (
              <CommentItem key={i} />
            ))}
          </div>
          {/* <div className="flex h-[64px] items-center"></div> */}
        </div>

        <div className="w-[260px] ">
          <div className="w-full h-[320px] p-2 flex flex-col ml-4">
            <h2>Filter</h2>
            <span>Product</span>
            <DropdownInput className="w-[100%]" />
            <span>Status</span>
            <DropdownInput
              className=" w-[100%] mb-2"
              options={[
                { key: '1', value: 'Options1' },
                { key: '2', value: 'Options2' },
                { key: '3', value: 'Options3' },
                { key: '4', value: 'Options4' },
                { key: '5', value: 'Options5' },
                { key: '6', value: 'Options6' },
                { key: '7', value: 'Options7' },
                { key: '8', value: 'Options8' },
              ]}
              onChange={value => {
                console.log(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const CommentItem = () => {
  return (
    <>
      <div className="comment-item">
        <div className="comment-item__content">
          <div
            className="comment-item__content__image"
            style={{ borderRight: '1px solid #d9d9d988' }}
          >
            <img
              src={`../assets/svg/products/call.svg`}
              style={{
                objectFit: 'contain',
                width: 48,
                height: 48,
              }}
              className="m-0 mr-2"
            />

            <span style={{}}>C-MEET</span>
          </div>
          <div className="flex flex-1 flex-col p-2">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="h-[32px] w-[32px] rounded-full bg-[#aaa] mr-2"></div>
                <span style={{ fontWeight: 600 }}>Van Teo</span>
              </div>
              <Checkbox value={true} />
            </div>
            <div>
              <p
                className="m-0 text-[#444]"
                style={{ fontFamily: 'SVN-Gilroy' }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </p>
            </div>
            <div className="flex justify-between">
              <div className="text-sm text-[#aaa] flex items-center">
                <FontAwesomeIcon className="mr-1" icon={faCalendar} />
                29:00 05/06/2023
              </div>
              <div className="text-sm flex">
                <div
                  className="mr-2 cursor-pointer flex items-center"
                  style={{ fontWeight: 600, color: '#82CD47' }}
                >
                  Approve
                  <FontAwesomeIcon className="ml-1" icon={faCheck} />
                </div>
                <div
                  className="mr-2 cursor-pointer flex items-center"
                  style={{ fontWeight: 600, color: '#aaa' }}
                >
                  Dissemble
                  <FontAwesomeIcon className="ml-1" icon={faClose} />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col w-[180px] bg-[#a22]">
            <div className="">
              <div className=""></div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default CommentPage;
